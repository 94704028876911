<template>
  <validation-observer ref="changePassWordRef" #default="{ valid }">
    <b-modal
      ref="modal"
      title="Đổi mật khẩu"
      centered
      cancel-variant="outline-secondary"
      @ok.prevent="handleChangePassword"
      :ok-disabled="loading || !valid"
      @cancel="resetForm"
    >
      <b-form>
        <b-row>
          <!-- Field: Password -->
          <b-col cols="8">
            <b-form-group label="Mật khẩu mới" label-for="new-password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                :rules="{
                  required: true,
                  regex: /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,25}$/,
                }"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="password"
                    :type="passwordTypeNew"
                    name="new-password"
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] &&
                  "Mật khẩu phải từ 6 đên 25 kí tự và có ít nhất 1 chữ và số."
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Confirm Password -->
          <b-col cols="8">
            <b-form-group
              label="Nhập lại mật khẩu mới"
              label-for="confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="confirmPassword"
                    :type="passwordConfirm"
                    name="confirm-password"
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] && "Mật khẩu không khớp"
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-cancel>
        <span>Từ chối</span>
      </template>
      <template #modal-ok>
        <Spinner v-if="loading" />
        <span v-else>Chấp nhận</span>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    VBModal,
    ValidationObserver,
    ValidationProvider,
    Spinner,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordTypeNew: "password",
      passwordConfirm: "password",
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.apps.users.changePassword.loading,
    }),
    passwordToggleIconNew() {
      return this.passwordTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconConfirm() {
      return this.passwordConfirm === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      changePassword: "users/changePassword/changePassword",
    }),
    async handleChangePassword() {
      const { userId } = this.$router.currentRoute.params;
      const { password } = this;
      const res = await this.changePassword({
        userId,
        password,
      });
      if (!res) return;
      this.showToastSuccess(null, "Đổi mật khẩu thành công.");
      this.resetForm();
    },
    handleShowModal() {
      this.$refs.modal.show();
    },
    resetForm() {
      this.password = "";
      this.confirmPassword = "";
      this.$refs.changePassWordRef.reset();
      this.$refs.modal.hide();
    },
    togglePasswordNew() {
      this.passwordTypeNew =
        this.passwordTypeNew === "password" ? "text" : "password";
    },
    togglePasswordConfirm() {
      this.passwordConfirm =
        this.passwordConfirm === "password" ? "text" : "password";
    },
  },
  setup() {
    return {
      ...useToastHandler(),
    };
  },
};
</script>
