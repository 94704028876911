import { formatCurrency } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";
// Notification

export default function useUsers() {
  const statusFilter = ref(null);
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const tableColumns = [
    {
      thClass: "text-center",
      tdClass: "text-center",
      key: "stt",
      label: "STT",
    },
    {
      key: "fullName",
      label: "Tên khách hàng",
    },
    {
      key: "username",
      label: "Tên người dùng",
    },

    {
      key: "totalOrder",
      label: "số đơn hàng",
      thClass: "text-center",
      tdClass: "text-center",
      sortable: true,
      formatter: (value) => formatCurrency(value, { style: "decimal" }),
    },
    {
      key: "createdDate",
      label: "Ngày đăng kí",
      sortable: true,
    },
  ];

  watch([statusFilter], () => {
    refUsers.value.refresh();
  });

  return {
    tableColumns,
    statusFilter,
    sortBy,
    isSortDirDesc,
    refUsers,
  };
}
