export const TransactionsType = {
  DepositRequest: 1,
  Order: 2,
  Surcharge: 3,
  Other: 4,
  PaymentBalance: 5,
  CommissionBalance: 6,
};

export const TransactionsContent = {
  [TransactionsType.DepositRequest]: "Yêu cầu nạp tiền",
  [TransactionsType.Order]: "Tiền đặt hàng",
  [TransactionsType.Surcharge]: "Tiền phụ phí",
  [TransactionsType.Other]: "Khác",
  [TransactionsType.PaymentBalance]: "Thanh toán số dư",
  [TransactionsType.CommissionBalance]: "Tiền hoa hồng", 
};
