import { DepositRequestEnum } from "@/@core/utils/enums/deposit-request.enum";

export default function useChangeStatus() {
  const statusOptions = [
    { label: "Chấp nhận", value: DepositRequestEnum.Approved },
    { label: "Huỷ", value: DepositRequestEnum.Reject },
  ];
  return {
    statusOptions,
  };
}
