export const DepositRequestEnum = {
  New: 0,
  Approved: 1,
  Reject: 2,
};

export const DepositRequestContent = {
  [DepositRequestEnum.New]: "Chờ xử lí",
  [DepositRequestEnum.Approved]: "Đã chấp nhận",
  [DepositRequestEnum.Reject]: "Đã huỷ",
};
