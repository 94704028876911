<template>
  <div>
    <LoadingPage v-if="loading && !userDetail" />
    <b-card v-if="userDetail">
      <!-- User  -->
      <b-tabs pills v-if="userDetail.roleId == RoleEnum.User">
        <!-- Info -->
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Info)"
          :active="tab === UserTab.Info"
        >
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Thông tin</span>
          </template>
          <UserInformation
            v-if="tab === UserTab.Info"
            :user-detail-props="userDetail"
            :handleUpdateUserDetail="handleUpdateUserDetail"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Transaction -->
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Transaction)"
          :active="tab === UserTab.Transaction"
        >
          <template #title>
            <feather-icon
              icon="TrendingUpIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Giao dịch</span>
          </template>
          <Transaction
            v-if="tab === UserTab.Transaction"
            :user-detail="userDetail"
            :handleRefreshUserDetail="handleRefreshUserDetail"
          />
        </b-tab>

        <!-- Order -->
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Order)"
          :active="tab === UserTab.Order"
        >
          <template #title>
            <feather-icon
              icon="ShoppingCartIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Đơn hàng </span>
          </template>
          <br />

          <span>
            <strong>Khách hàng: </strong>
            {{ userDetail.fullName }} - {{ userDetail.username }}</span
          >
          <Orders
            v-if="tab === UserTab.Order"
            :userId="String($router.currentRoute.params.userId)"
          />
        </b-tab>
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Payment)"
          :active="tab === UserTab.Payment"
        >
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">chuyển tiền</span>
          </template>
          <br />
          <span>
            <strong>Khách hàng: </strong>
            {{ userDetail.fullName }} - {{ userDetail.username }}</span
          >

          <DepositRequest
            v-if="tab === UserTab.Payment"
            :user-detail="userDetail"
            :handleRefreshUserDetail="handleRefreshUserDetail"
          />
        </b-tab>
      </b-tabs>

      <!-- CTV -->
      <b-tabs pills v-if="userDetail.roleId == RoleEnum.CustomerSupport">
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Info)"
          :active="tab === UserTab.Info"
        >
          <!-- Info -->
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Thông tin</span>
          </template>
          <UserInformation
            v-if="tab === UserTab.Info"
            :user-detail-props="userDetail"
            :handleUpdateUserDetail="handleUpdateUserDetail"
            class="mt-2 pt-75"
          />
        </b-tab>
        <!-- Ref Affiliate -->
        <b-tab
          @click.prevent="handleChangeTab(UserTab.RefAffiliate)"
          :active="tab === UserTab.RefAffiliate"
        >
          <template #title>
            <feather-icon
              icon="ShoppingCartIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Khách hàng</span>
          </template>
          <Customer
            v-if="tab === UserTab.RefAffiliate"
            :user-detail="userDetail"
          />
        </b-tab>

        <!-- Transaction -->
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Transaction)"
          :active="tab === UserTab.Transaction"
        >
          <template #title>
            <feather-icon
              icon="TrendingUpIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Giao dịch</span>
          </template>
          <Transaction
            v-if="tab === UserTab.Transaction"
            :handleRefreshUserDetail="handleRefreshUserDetail"
            :user-detail="userDetail"
          />
        </b-tab>
      </b-tabs>

      <!-- Admin -->
      <b-tabs pills v-if="userDetail.roleId == RoleEnum.Admin">
        <!-- Info -->
        <b-tab
          @click.prevent="handleChangeTab(UserTab.Info)"
          :active="tab === UserTab.Info"
        >
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Thông tin</span>
          </template>
          <UserInformation
            v-if="tab === UserTab.Info"
            :user-detail-props="userDetail"
            :handleUpdateUserDetail="handleUpdateUserDetail"
            class="mt-2 pt-75"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { UserTab } from "@/@core/utils/enums/tab.enum";
import { RoleEnum } from "@/@core/utils/enums/user.enum";
import { customTime } from "@/helpers/untils";
import LoadingPage from "@core/components/LoadingPage";
import { BCard, BCol, BRow, BTab, BTabs } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import DepositRequest from "../../deposit-request/DepositRequest.vue";
import Orders from "../../order/order-list/Orders.vue";
import UserInformation from "./Info/UserInformation";
import Customer from "./ctv/customer/Customer.vue";
import Transaction from "./transaction/Transaction";
export default {
  components: {
    LoadingPage,
    BCard,
    BTab,
    BTabs,
    BRow,
    BCol,
    UserInformation,
    Transaction,
    Customer,
    Orders,
    DepositRequest,
  },
  data() {
    return {
      userDetail: undefined,
      tab: undefined,
      balance: 0,
    };
  },

  computed: {
    ...mapState({
      loading: (s) => s.apps.users.userDetail.loading,
    }),
  },
  methods: {
    ...mapActions({
      getUser: "users/userDetail/getUser",
    }),
    async getUserDetail() {
      const { userId } = this.$router.currentRoute.params;
      const userDetail = await this.getUser({ userId });

      this.userDetail = {
        ...userDetail,
        dob: customTime(userDetail.dob, "DD-MM-YYYY"),
      };
    },
    handleChangeTab(tab) {
      const curTab = this.$router.currentRoute.query.tab;
      if (tab !== curTab) {
        this.$router.replace({
          query: { tab: tab },
        });
        this.tab = tab;
      }
    },
    handleUpdateUserDetail(userUpdate) {
      this.userDetail = { ...this.userDetail, userUpdate };
    },
    async handleRefreshUserDetail() {
      await this.getUserDetail();
    },
  },
  async created() {
    this.tab =
      this.$router.currentRoute.query.tab ?? this.handleChangeTab(UserTab.Info);
    await this.getUserDetail();
  },
  setup() {
    return { RoleEnum, UserTab };
  },
};
</script>
