<template>
  <div>
    <validation-observer
      ref="userInformationRef"
      #default="{ invalid }"
      @input="isChanged"
    >
      <b-card no-body class="mb-0">
        <!-- User -->
        <b-row cols="12" v-if="userDetail.roleId == RoleEnum.User">
          <b-col cols="3" class="text-center">
            <b-media class="mb-2 text-center">
              <b-avatar
                ref="previewEl"
                :text="genAvatarText(userDetail.fullName)"
                :variant="`light-success`"
                size="70px"
              />
            </b-media>
            <h6 class="mb-1">
              {{ userDetail.username }}
            </h6>
            <p>({{ RoleInfoParse[userDetail.roleId] }})</p>
          </b-col>

          <b-col cols="3" md="2" class="text-center">
            <h5><strong>Tỷ giá</strong></h5>
            <p>
              {{
                formatCurrency(userDetail.exchangeRate, { style: "decimal" })
              }}
            </p>
          </b-col>
          <b-col cols="3" md="2" class="text-center">
            <h5><strong>Số đơn hàng</strong></h5>
            <p>{{ formatCurrency(totalOrder, { style: "decimal" }) }}</p>
          </b-col>
          <b-col cols="3" md="2" class="text-center">
            <h5><strong>Số dư</strong></h5>
            <p>{{ formatCurrency(userDetail.balance || 0) }}</p>
          </b-col>
        </b-row>

        <!-- CTV -->
        <b-row cols="12" v-if="userDetail.roleId == RoleEnum.CustomerSupport">
          <b-col cols="3" class="text-center">
            <b-media class="mb-2 text-center">
              <b-avatar
                ref="previewEl"
                :text="genAvatarText(userDetail.fullName)"
                :variant="`light-success`"
                size="70px"
              />
            </b-media>
            <h6 class="mb-1">
              {{ userDetail.username }}
            </h6>
            <p>({{ RoleInfoParse[userDetail.roleId] }})</p>
          </b-col>

          <b-col cols="3" md="2" class="text-center">
            <h5><strong>Chiết khấu</strong></h5>
            <p>
              {{ formatCurrency(userDetail.discount, { style: "decimal" }) }} %
            </p>
          </b-col>
          <b-col cols="3" md="2" class="text-center">
            <h5><strong>Khách hàng</strong></h5>
            <p>{{ formatCurrency(totalRefAffiliate, { style: "decimal" }) }}</p>
          </b-col>
          <b-col cols="3" md="2" class="text-center">
            <h5><strong>Số dư</strong></h5>
            <p>{{ formatCurrency(userDetail.balance || 0) }}</p>
          </b-col>
        </b-row>

        <!-- Admin -->
        <b-row cols="12" v-if="userDetail.roleId == RoleEnum.Admin">
          <b-col cols="3" class="text-center">
            <b-media class="mb-2 text-center">
              <b-avatar
                ref="previewEl"
                :text="genAvatarText(userDetail.fullName)"
                :variant="`light-success`"
                size="70px"
              />
            </b-media>
            <h6 class="mb-1">
              {{ userDetail.username }}
            </h6>
            <p>({{ RoleInfoParse[userDetail.roleId] }})</p>
          </b-col>
        </b-row>

        <b-form class="mt-2">
          <!-- Email -->
          <b-row cols="12">
            <b-col cols="12" md="3">
              <b-form-group label="Email" label-for="email">
                <b-form-input disabled id="email" v-model="userDetail.email" />
              </b-form-group>
            </b-col>

            <!-- Tên Người dùng -->
            <b-col cols="12" md="3">
              <b-form-group label="Tên" label-for="fullName">
                <validation-provider
                  #default="{ errors }"
                  name="fullName"
                  vid="fullName"
                  rules="required|max:60|min:1"
                >
                  <b-form-input id="fullName" v-model="userUpdate.fullName" />
                  <small class="text-danger">{{
                    errors[0] && "Tên không hợp lệ."
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Số điện thoại -->
            <b-col cols="12" md="3">
              <b-form-group label="Số điện thoại" label-for="phoneNumber">
                <validation-provider
                  #default="{ errors }"
                  name="phoneNumber"
                  vid="phoneNumber"
                  :rules="{
                    required: true,
                    regex: /^(0|\+)[0-9]{8,14}$/,
                  }"
                >
                  <b-form-input
                    id="phoneNumber"
                    v-model="userUpdate.phoneNumber"
                  />
                  <small class="text-danger">{{
                    errors[0] && "Số điện thoại không hợp lệ."
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Ngày sinh -->
            <b-col cols="12" md="3">
              <b-form-group label="Ngày sinh" label-for="dob">
                <validation-provider
                  name="dob"
                  vid="dob"
                  rules="required"
                  #default="{ errors }"
                >
                  <flat-pickr
                    v-model="userUpdate.dob"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y' }"
                  />
                  <small class="text-danger">{{
                    errors[0] && "Ngày sinh không hợp lệ."
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="loading || invalid || !changed"
                @click.prevent="handleUpdateUser"
              >
                <Spinner v-if="loading" />
                <template v-else> Cập nhật </template>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
    <!-- Admin / CTV -->
    <div v-if="userDetail.roleId === RoleEnum.CustomerSupport" class="mt-2">
      <CTVInfoPartial
        :user-detail="userDetail"
        :handleUpdateInformation="handleUpdateInformation"
      />
    </div>
    <div v-if="userDetail.roleId === RoleEnum.User" class="mt-2">
      <UserInfoPartial
        :handleChangePassword="handleChangePassword"
        :user-detail="userDetail"
        :handleUpdateInformation="handleUpdateInformation"
      />
    </div>
    <div v-if="userDetail.roleId === RoleEnum.Admin" class="mt-2">
      <AdminInfoPartial
        :handleChangePassword="handleChangePassword"
        :user-detail="userDetail"
      />
    </div>
  </div>
</template>
<script>
import { StatsEnum } from "@/@core/utils/enums/stats.enum";
import { RoleEnum, RoleInfoParse } from "@/@core/utils/enums/user.enum";
import { functionProp, objectProp } from "@/helpers/props";
import { compareObject, formatCurrency, genAvatarText } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BMedia,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapState } from "vuex";
import AdminInfoPartial from "./InfoPartial/AdminPartial.vue";
import CTVInfoPartial from "./InfoPartial/CTVInfoPartial";
import UserInfoPartial from "./InfoPartial/UserInfoPartial.vue";
export default {
  components: {
    BAvatar,
    BMedia,
    BRow,
    BCard,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BButton,
    flatPickr,
    UserInfoPartial,
    CTVInfoPartial,
    Spinner,
    AdminInfoPartial,
  },
  props: {
    userDetailProps: objectProp(),
    handleUpdateUserDetail: functionProp(),
  },
  data() {
    return {
      userDetail: { ...this.userDetailProps },
      userUpdate: { ...this.userDetailProps },
      changed: false,
      totalOrder: 0,
      totalRefAffiliate: 0,
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.apps.users.updateProfile.loading,
    }),
  },
  created() {
    this?.userDetail?.statsUser?.forEach((stats) => {
      switch (stats?.statsType) {
        case StatsEnum.totalOrder:
        // return (this.totalOrder = stats.value);
        case StatsEnum.totalRefAffiliate:
          return (this.totalRefAffiliate = stats.value);
      }
    });
    this.totalOrder = this?.userDetail?.totalOrder || 0;
  },
  methods: {
    ...mapActions({
      updateProfile: "users/updateProfile/updateProfile",
    }),
    async handleChangePassword({ password }) {
      const { userId } = this.userDetail;
      await this.changePassword({ userId, password });
    },
    async handleUpdateUser() {
      const payload = compareObject(this.userDetail, this.userUpdate);
      const { userId } = this.userDetail;

      const user = await this.updateProfile({ ...payload, userId });
      if (!user) return;

      this.userDetail = { ...this.userDetail, ...this.userUpdate };
      this.handleUpdateUserDetail(this.userDetail);
      this.showToastSuccess("Thành công", "Cập nhật thông tin thành công.");
      this.resetForm();
    },
    isChanged() {
      this.changed = !!compareObject(this.userDetail, this.userUpdate);
    },
    resetForm() {
      this.changed = false;
      this.$refs.userInformationRef.reset();
    },
    handleUpdateInformation(dataUpdate) {
      this.userDetail = { ...this.userDetail, ...dataUpdate };
    },
  },

  setup() {
    return {
      ...useToastHandler(),
      genAvatarText,
      RoleInfoParse,
      formatCurrency,
      RoleEnum,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
