<template>
  <div class="mt-2">
    <span class="cursor-pointer"> <strong>CTV liên kết: </strong></span>
    <span
      ><a
        v-if="userDetail.refAffiliate"
        href="http://localhost:8080/user/6?tab=1"
        >{{ userDetail.refAffiliate }}</a
      >
      {{ !userDetail.refAffiliate ? "-" : "" }}
    </span>
    <!-- :disabled="loading" -->
    <!-- Đổi mật khẩu -->
    <b-col class="mt-2">
      <b-row cols="12">
        <!-- Đổi mật khẩu -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="changePassword"
          @click.prevent="showModal('change-password')"
        >
          Đổi mật khẩu
        </b-button>

        <!-- Điều chỉnh tỷ giá -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="changeExchangeRate"
          @click.prevent="showModal('change-exchange-rate')"
        >
          Điều chỉnh tỷ giá
        </b-button>

        <!-- Nạp tiền -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="depositBalance"
          @click.prevent="showModal('deposit-balance')"
        >
          Nạp tiền
        </b-button>

        <!-- Nạp tiền -->
        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="depositBalance"
          @click.prevent="showModal('verify-account')"
        >
          <Spinner v-if="loadingVerifyAccount" />
          <span v-else>
            {{
              allowed_search_product
                ? "Huỷ xác minh tài khoản"
                : "Xác minh tài khoản"
            }}</span
          >
        </b-button>
      </b-row>
    </b-col>

    <ChangePassword ref="changePasswordRef" />
    <DepositBalance
      v-if="userDetail"
      :handleUpdateInformation="handleUpdateInformation"
      :user-detail="userDetail"
      ref="depositBalanceRef"
    />
    <ChangeExchangeRate
      v-if="userDetail"
      :handleUpdateInformation="handleUpdateInformation"
      :user-detail="userDetail"
      ref="changeExchangeRateRef"
    />
    <VerifyAccount
      v-if="userDetail"
      :handleVerifyAccount="handleVerifyAccount"
      :allowed_search_product="allowed_search_product"
      ref="verifyAccountRef"
    />
  </div>
</template>

<script>
import { COLLECTION_NAME, firebaseService } from "@/firebase/firebase";
import { functionProp, objectProp } from "@/helpers/props";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import { BButton, BCol, BForm, BRow } from "bootstrap-vue";
import ChangeExchangeRate from "./modal/ChangeExchangeRate.vue";
import ChangePassword from "./modal/ChangePassword.vue";
import DepositBalance from "./modal/DepositBalance.vue";
import VerifyAccount from "./modal/VerifyAccount.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    ChangePassword,
    DepositBalance,
    ChangeExchangeRate,
    VerifyAccount,
    Spinner,
  },
  data() {
    return {
      password: "",
      allowed_search_product: undefined,
      docFirebase: undefined,
      loadingVerifyAccount: true,
    };
  },
  props: {
    userDetail: objectProp(),
    handleUpdateInformation: functionProp(),
  },
  created() {
    const { username } = this.userDetail;
    this.docFirebase = firebaseService.docFirebase(
      COLLECTION_NAME.User,
      username
    );
    firebaseService.onSnapshot(this.docFirebase, (data) => {
      const allowed_search_product = data.data()?.allowed_search_product;
      this.allowed_search_product = allowed_search_product;
      this.loadingVerifyAccount = false;
    });
  },

  methods: {
    showModal(type) {
      switch (type) {
        case "change-password":
          return this.$refs.changePasswordRef.handleShowModal();
        case "deposit-balance":
          return this.$refs.depositBalanceRef.handleShowModal();
        case "change-exchange-rate":
          return this.$refs.changeExchangeRateRef.handleShowModal();
        case "verify-account":
          return this.$refs.verifyAccountRef.handleShowModal();
      }
    },
    async handleVerifyAccount() {
      this.loadingVerifyAccount = true;
      const messageToast = this.allowed_search_product
        ? "Huỷ xác minh tài khoản"
        : "Xác minh tài khoản";
      try {
        const allowed_search_product = !this.allowed_search_product;
        await firebaseService.setDoc(this.docFirebase, {
          allowed_search_product,
        });
        this.showToastSuccess(null, `${messageToast} thành công`);
      } catch (error) {
        this.showToastError(null, `${messageToast} thất bại`);
      }
      this.loadingVerifyAccount = false;
    },
  },
  setup() {
    return {
      ...useToastHandler(),
    };
  },
};
</script>
