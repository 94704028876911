<template>
  <b-modal
    ref="modal"
    title="Cập nhật trạng thái"
    centered
    cancel-variant="outline-secondary"
    @ok.prevent="handleChangeStatus"
    :ok-disabled="loading"
    @cancel="resetForm"
  >
    <b-form>
      <b-row>
        <b-col cols="12" md="8" x class="mb-md-0 mb-2">
          <label>Trạng Thái</label>
          <v-select
            :clearable="false"
            v-model="statusDefault"
            :options="statusOptions"
          />
        </b-col>
      </b-row>
    </b-form>
    <template #modal-cancel>
      <span>Từ chối</span>
    </template>
    <template #modal-ok>
      <Spinner v-if="loading" />
      <span v-else>Chấp nhận</span>
    </template>
  </b-modal>
</template>

<script>
import { functionProp, objectProp } from "@/helpers/props";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import useChangeStatus from "./useChangeStatus";
export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    VBModal,
    Spinner,
    BFormTextarea,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    handleRefreshData: functionProp(),
    userDetail: objectProp(),
  },
  data() {
    return {
      statusDefault: this.statusOptions[0],
      dataRefresh: null,
    };
  },
  computed: {
    ...mapState({
      loading: (s) => {
        return s.apps.depositRequest.changeStatus.loading;
      },
    }),
  },
  methods: {
    ...mapActions({
      changeStatusRequest: "depositRequest/changeStatus/changeStatus",
    }),
    async handleChangeStatus() {
      const { depositId, index } = this.dataRefresh;
      const { value: status } = this.statusDefault;
      const res = await this.changeStatusRequest({
        depositId,
        status,
      });
      if (!res) return;

      /* Refresh Data*/
      await this.handleRefreshData();
      this.showToastSuccess(null, "Cập nhật trạng thái thành công.");
      this.resetForm();
    },
    handleShowModal(dataRefresh) {
      this.$refs.modal.show();
      this.dataRefresh = dataRefresh;
    },
    resetForm() {
      this.dataRefresh = null;
      this.$refs.modal.hide();
    },
  },
  setup() {
    return {
      ...useToastHandler(),
      ...useChangeStatus(),
    };
  },
};
</script>

<style lang="scss">
.tbody-tr-class td[aria-colindex="1"] {
  width: 210px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
