import {
  DepositRequestContent,
  DepositRequestEnum,
} from "@/@core/utils/enums/deposit-request.enum";
import { formatCurrency } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";
// Notification

export default function useUsers() {
  const statusFilter = ref(null);
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const tableColumns = [
    {
      key: "stt",
      label: "STT",
    },
    {
      key: "username",
      label: "Tên người dùng",
    },
    {
      key: "fullName",
      label: "Khách hàng",
    },
    {
      key: "balance",
      label: "Số tiền",
      formatter: (value) => formatCurrency(value, { style: "currency" }),
    },
    {
      key: "status",
      label: "Trang thái",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "createdDate",
      label: "Ngày tạo",
      thClass: "text-center",
      sortable: true,
    },
    {
      key: "action",
      label: "Cập nhật",
      thClass: "text-center",
      tdClass: "text-center",
    },
  ];

  const statusOptions = [
    {
      label: DepositRequestContent[DepositRequestEnum.New],
      value: DepositRequestEnum.New,
    },
    {
      label: DepositRequestContent[DepositRequestEnum.Approved],
      value: DepositRequestEnum.Approved,
    },
    {
      label: DepositRequestContent[DepositRequestEnum.Reject],
      value: DepositRequestEnum.Reject,
    },
  ];
  watch([statusFilter], () => {
    refUsers.value.refresh();
  });

  const resolveType = (type) => {
    switch (type) {
      case DepositRequestEnum.New:
        return "secondary";
      case DepositRequestEnum.Approved:
        return "success";
      case DepositRequestEnum.Reject:
        return "danger";
    }
  };

  return {
    tableColumns,
    statusOptions,
    statusFilter,
    sortBy,
    isSortDirDesc,

    refUsers,
    resolveType,
  };
}
