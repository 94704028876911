<template>
  <div>
    <b-card no-body class="mb-0 mt-1">
      <Filters :status-filter.sync="statusFilter" />
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ localize["table_show"] }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Table Control -->
      <b-table
        ref="refUserListTable"
        :items="fetchDataTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        primary-key="id"
        hover
      >
        <template #empty>
          <div v-if="state.loading" class="text-center my-2">
            <p>{{ localize["table_loading"] }}</p>
          </div>
          <div
            v-if="!state.loading && totalRows === 0"
            class="text-center my-2"
          >
            {{ localize["table_empty"] }}
          </div>
        </template>

        <!-- Custom Column -->
        <template #cell(stt)="{ index }">
          <div style="width: 30px">
            {{ index + 1 }}
          </div>
        </template>
        <template #cell(username)="{ item }">
          <div style="min-width: 130px; max-width: 200px">
            {{ item.username }}
          </div>
        </template>
        <template #cell(fullName)="{ item }">
          <div style="min-width: 110px; max-width: 200px">
            {{ item.fullName }}
          </div>
        </template>
        <template #cell(status)="{ item }">
          <div class="text-center">
            <b-badge
              pill
              :variant="`${resolveType(item.status)}`"
              class="text-capitalize"
            >
              {{ DepositRequestContent[item.status] }}
            </b-badge>
          </div>
        </template>
        <template #cell(action)="{ item, index }">
          <div style="width: 120px">
            <feather-icon
              v-if="item.status == DepositRequestEnum.New"
              icon="EditIcon"
              size="16"
              class="mr-0 mr-sm-50 ml-2 cursor-pointer"
              @click="handleUpdate(item, index)"
              :disabled="item.status != DepositRequestEnum.New"
            />
          </div>
        </template>

        <template #cell(createdDate)="{ item }">
          <div
            class="text-center"
            style="display: flex; justify-content: center"
          >
            <div style="width: 150px">
              {{ customTime(item.createdDate) }}
            </div>
          </div>
        </template>
      </b-table>

      <!-- Pagination < Pre/Next > -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <ChangeStatus
      :handleRefreshData="handleRefreshData"
      ref="changeStatusRef"
    />
  </div>
</template>

<script>
import { EnvConfig } from "@/@core/config/index";
import {
  DepositRequestContent,
  DepositRequestEnum,
} from "@/@core/utils/enums/deposit-request.enum";
import { OrderStatusParse } from "@/@core/utils/enums/order.enum";
import { SortByEnum } from "@/@core/utils/enums/sort-by.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { functionProp, objectProp } from "@/helpers/props";
import { customTime } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import Filters from "./Filters.vue";
import ChangeStatus from "./modal/ChangeStatus.vue";
import useDepositRequest from "./useDepositRequest";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    Filters,
    BButton,
    BCardHeader,
    BCardBody,
    ChangeStatus,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    userDetail: objectProp(),
    handleRefreshUserDetail: functionProp(),
  },
  data() {
    return {
      depositRequests: [],
    };
  },
  computed: {
    ...mapState({
      state: (s) => {
        return s.apps.depositRequest.depositRequests;
      },
    }),
  },
  async created() {
    await this.fetchDataTable();
  },
  methods: {
    ...mapActions({
      getDepositRequests: "depositRequest/depositRequests/getDepositRequests",
    }),
    async fetchDataTable() {
      const { userId } = this.userDetail;
      const data = await this.getDepositRequests({
        ...(userId ? { userId } : {}),
        limit: this.perPage,
        page: this.currentPage,
        order: this.sortBy ?? null,
        status: this.statusFilter,
        by: this.sortBy
          ? this.isSortDirDesc
            ? SortByEnum.DESC
            : SortByEnum.ASC
          : null,
      });
      this.totalRows = this.state.totalPage;
      return data;
    },
    async handleRefreshData() {
      this.refUserListTable.refresh();
      this.handleRefreshUserDetail();
    },
    handleUpdate(item, index) {
      this.$refs.changeStatusRef.handleShowModal({ ...item, index });
    },
  },
  mounted() {
    this.refUsers = this.refUserListTable;
  },
  setup() {
    return {
      ...useTable(),
      ...useDepositRequest(),
      ...useToastHandler(),
      localize,
      OrderStatusParse,
      EnvConfig,
      customTime,
      DepositRequestContent,
      DepositRequestEnum,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
