<template>
  <div class="loading-bg">
    <div class="loading-logo">
      <!-- logo-loading -->
      <!-- <img src="@/assets/images/logo/csm_logo.png" alt="Logo" /> -->
    </div>
    <div class="loading">
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading-bg {
  padding: 150px 0;
  position: relative;
}
</style>
