import {
  TransactionsContent,
  TransactionsType,
} from "@/@core/utils/enums/transaction.enum";
import { customTime, formatCurrency } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";
// Notification

export default function useUsers() {
  const typeFilter = ref(null);
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const tableColumns = [
    {
      key: "transactionId",
      label: "ID",
    },
    {
      key: "content",
      label: "Nội dung",
    },
    {
      key: "fromBalance",
      label: "Số dư trước",
      formatter: (value) => formatCurrency(value, { style: "currency" }),
    },

    {
      key: "balance",
      label: "Số dư giao dịch",
      formatter: (value) => formatCurrency(value, { style: "currency" }),
    },
    {
      key: "toBalance",
      label: "Số dư sau",
      formatter: (value) => formatCurrency(value, { style: "currency" }),
    },

    {
      key: "refOrderId",
      label: "ID Đơn hàng",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "type",
      label: "Loại",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "createdDate",
      label: "Ngày tạo",
      thClass: "text-center",
      formatter: (value) => {
        return customTime(value);
      },
      sortable: true,
    },
  ];

  const typeOptions = [
    {
      label: TransactionsContent[TransactionsType.DepositRequest],
      value: TransactionsType.DepositRequest,
    },
    {
      label: TransactionsContent[TransactionsType.Order],
      value: TransactionsType.Order,
    },
    {
      label: TransactionsContent[TransactionsType.Surcharge],
      value: TransactionsType.Surcharge,
    },
    {
      label: TransactionsContent[TransactionsType.Other],
      value: TransactionsType.Other,
    },
    {
      label: TransactionsContent[TransactionsType.PaymentBalance],
      value: TransactionsType.PaymentBalance,
    },
    {
      label: TransactionsContent[TransactionsType.CommissionBalance],
      value: TransactionsType.CommissionBalance,
    },
  ];
  watch([typeFilter], () => {
    refUsers.value.refresh();
  });

  const resolveType = (type) => {
    switch (type) {
      case TransactionsType.DepositRequest:
        return "secondary";
      case TransactionsType.Order:
        return "success";
      case TransactionsType.Surcharge:
        return "primary";
      case TransactionsType.Other:
        return "warning";
      case TransactionsType.PaymentBalance:
        return "info";
      case TransactionsType.CommissionBalance:
        return "success";
    }
  };

  return {
    tableColumns,
    typeOptions,
    typeFilter,
    sortBy,
    isSortDirDesc,

    refUsers,
    resolveType,
  };
}
