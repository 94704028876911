<template>
  <div class="mt-2">
    <!-- Đổi mật khẩu -->
    <b-col class="mt-2">
      <b-row cols="12">
        <!-- Đổi mật khẩu -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="changePassword"
          @click.prevent="showModal('change-password')"
        >
          Đổi mật khẩu
        </b-button>
      </b-row>
    </b-col>
    <ChangePassword ref="changePasswordRef" />
  </div>
</template>

<script>
import { functionProp, objectProp } from "@/helpers/props";
import { BButton, BCol, BRow } from "bootstrap-vue";
import ChangePassword from "./modal/ChangePassword.vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    ChangePassword,
  },
  props: {
    userDetail: objectProp(),
    handleUpdateInformation: functionProp(),
  },
  methods: {
    showModal(type) {
      switch (type) {
        case "change-password":
          return this.$refs.changePasswordRef.handleShowModal();
      }
    },
  },
};
</script>
