<template>
  <div>
    <!-- Table Container Card -->

    <b-card no-body class="mb-0">
      <!-- <Filters :status-filter.sync="statusFilter" /> -->
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ localize["table_show"] }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <!-- <b-col cols="12" md="4">
            <div class="d-flex justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col> -->
        </b-row>
      </div>
      <!-- Table Control -->
      <b-table
        ref="refUserListTable"
        :items="fetchDataTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        primary-key="id"
        hover
        style="min-height: 500px"
      >
        <template #empty>
          <div v-if="state.loading" class="text-center my-2">
            <p>{{ localize["table_loading"] }}</p>
          </div>
        </template>

        <!-- Custom Column -->

        <template #cell(orderId)="{ item, index }">
          <div @click="handleRowClicked(item, index)">
            <div v-if="index === 0">
              <b-form-input
                style="height: 38px; width: 155px"
                placeholder="Mã ĐH"
                id="quality"
                v-model="search"
              />
            </div>
            <div v-else style="width: 155px">
              {{ item.orderId }}
            </div>
          </div>
        </template>

        <template #cell(codTaoBao)="{ item, index }">
          <div v-if="index === 0">
            <b-form-input
              style="height: 38px; width: 150px"
              placeholder="Mã taobao"
              id="quality"
              v-model="codTaoBao"
            />
          </div>
          <div v-else class="text-wrap" style="width: 100px">
            <!-- {{ item.codTaoBao || "-" }} -->
            <b-form-input
              style="height: 30px; width: 150px"
              id="quality"
              v-model="orders[index].codTaoBao"
              :disabled="
                codUpdate.indexUpdate == index &&
                codUpdate.key == 'codTaoBao' &&
                updateOrderLoading
              "
              @change="handleCodUpdate('codTaoBao', item, index)"
            />
          </div>
        </template>

        <template #cell(codTracking)="{ item, index }">
          <div v-if="index === 0">
            <b-form-input
              style="height: 38px; width: 150px"
              placeholder="Mã tracking TQ"
              id="quality"
              v-model="codTracking"
            />
          </div>
          <div v-else class="text-wrap" style="width: 120px">
            <b-form-input
              style="height: 30px; width: 150px"
              id="quality"
              v-model="orders[index].codTracking"
              :disabled="
                codUpdate.indexUpdate == index &&
                codUpdate.key == 'codTracking' &&
                updateOrderLoading
              "
              @change="handleCodUpdate('codTracking', item, index)"
            />
            <!-- {{ item.codTracking || "-" }} -->
          </div>
        </template>

        <template #cell(fullName)="{ item, index }">
          <div @click="handleRowClicked(item, index)">
            <div v-if="index === 0 && !userId">
              <v-select
                :aria-autocomplete="false"
                :clearable="false"
                v-model="userFilter"
                :options="userFilterOption"
                class="filter-selector d-inline-block"
              />
            </div>

            <div v-else class="text-wrap text-center" style="width: 200px">
              {{ item.fullName }}
            </div>
          </div>
        </template>

        <template #cell(totalQuotePrices)="{ item, index }">
          <div @click="handleRowClicked(item, index)" style="width: 110px">
            <div v-if="index > 0">
              {{
                item.status === 0 ||
                (item.status === 3 && !item.totalQuotePrice)
                  ? formatCurrency(item.totalExchangePrice)
                  : formatCurrency(
                      (item.totalQuotePrice || 0) +
                        (item.exchangeServiceCosts || 0) +
                        (item.exchangeShipCosts || 0) +
                        (item.totalOtherPrice || 0)
                    )
              }}
              đ
            </div>
          </div>
        </template>

        <template #cell(isPaid)="{ item, index }">
          <div
            style="
              min-width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <b-form-checkbox
              v-if="
                index > 0 &&
                item.status >= OrderStatusEnum.Quoted &&
                item.status != OrderStatusEnum.Rejected
              "
              :key="index"
              v-model="orders[index].isPaid"
              :value="1"
              :unchecked-value="0"
              :disabled="!!orders[index].isPaid"
              @change="(val) => handlePayment(val, item, index)"
            />
          </div>
        </template>

        <template #cell(status)="{ item, index }">
          <div style="display: flex; justify-content: center">
            <div v-if="index === 0">
              <v-select
                :aria-autocomplete="false"
                :clearable="false"
                v-model="statusFilter"
                :options="statusOptions"
                class="status-filter-selector d-inline-block"
              />
            </div>
            <div
              style="display: flex; width: 180px; align-items: center; flex: 1"
              v-else
            >
              <div style="width: 160px; display: flex; justify-content: center">
                <b-badge
                  pill
                  :style="resolveStyleOrderStatus(item.status)"
                  :variant="`${resolveOrderStatus(item.status)}`"
                  class="text-capitalize"
                  style="
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-left: 14px;
                    padding-right: 14px;
                    margin-left: 40px;
                  "
                >
                  {{ OrderStatusParse[item.status] }}
                </b-badge>
              </div>
              <div
                v-if="item.status !== OrderStatusEnum.Completed"
                style="display: flex; justify-content: right; color: #4d73ad"
              >
                <feather-icon
                  :key="index"
                  icon="EditIcon"
                  size="16"
                  class="ml-1 cursor-pointer"
                  :style="resolveStyleOrderStatus(item.value)"
                  @click="handleUpdateStatus(item)"
                />
              </div>
            </div>
          </div>
        </template>

        <template #cell(createdDate)="{ item, index }">
          <div style="width: 150px; display: flex; justify-content: center">
            <div v-if="index > 0" class="text-wrap text-center">
              {{ customTime(item.createdDate) }}
            </div>
          </div>
        </template>
      </b-table>
      <!-- Pagination < Pre/Next > -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ totalRows === 0 ? 0 : dataMeta.from }} to
              {{ totalRows === 0 ? 0 : dataMeta.to - 1 }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <UpdateOrderModal
      ref="updateOrderModalRef"
      :order="{ orderId: order.orderId }"
      :handleUpdateOrderList="handleUpdateOrderList"
    />
    <Modal
      v-if="orderStatus && order.status !== OrderStatusEnum.Completed"
      ref="modalRef"
      :order="orderStatus"
      :refreshOrder="handleRefreshTable"
      :handleHideModal="handleHideModal"
    />
  </div>
</template>

<script>
import {
OrderStatusEnum,
OrderStatusParse,
PaidEnumParse,
} from "@/@core/utils/enums/order.enum";
import { SortByEnum } from "@/@core/utils/enums/sort-by.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { stringProp } from "@/helpers/props";
import { customTime, formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import {
BBadge,
BButton,
BCard,
BCol,
BFormCheckbox,
BFormInput,
BLink,
BPagination,
BRow,
BTable,
VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import Modal from "../detail/modal/Modal.vue";
import UpdateOrderModal from "../detail/modal/UpdateOrder.vue";
import Filters from "./Filters.vue";
import useOrders from "./useOrders";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    BButton,
    Filters,
    BFormCheckbox,
    BLink,
    UpdateOrderModal,
    Modal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    userId: stringProp(),
  },
  data() {
    return {
      userFilterOption: [{ label: "Tất cả", value: 0 }],
      orders: [],
      order: {},
      codUpdate: {
        key: null,
        indexUpdate: null,
      },
      orderStatus: null,
    };
  },
  computed: {
    ...mapState({
      state: (s) => s.apps.order.orders,
      userFilterLoading: (s) => s.apps.order.orderUserFilter.loading,
      updateOrderLoading: (s) => {
        return s.apps.order.updateOrder.loading;
      },
    }),
  },
  methods: {
    ...mapActions({
      getOrders: "order/orders/getOrders",
      getUserFilter: "order/orderUserFilter/getOrderUserFilter",
      updateOrder: "order/updateOrder/updateOrder",
    }),
    handleRowClicked(item, index, event) {
      if (index === 0) return;
      this.$router.push({
        name: "order-detail",
        params: { orderId: item.orderId },
      });
    },
    async fetchDataTable() {
      const { value: userIdFilter } = { ...this.userFilter };
      const { value: statusFilter } = { ...this.statusFilter };
      const { userId, codTaoBao, codTracking, search } = this;
      const data = await this.getOrders({
        ...(userId ? { userId } : {}), // get order detail if userId existed
        limit: this.perPage,
        page: this.currentPage,
        ...(statusFilter === -1 ? {} : { status: statusFilter }),
        order: this.sortBy ?? null,
        by: this.sortBy
          ? this.isSortDirDesc
            ? SortByEnum.DESC
            : SortByEnum.ASC
          : null,
        ...(!search ? {} : { search }),
        ...(!codTaoBao ? {} : { codTaoBao }),
        ...(!codTracking ? {} : { codTracking }),
        ...(userIdFilter ? { userId: userIdFilter } : {}),
      });
      this.totalRows = this.state.totalPage;
      this.orders = ["-", ...data];
      return this.orders;
    },

    /* payment */
    handlePayment(_, item, index) {
      this.order = item;
      this.$refs?.updateOrderModalRef?.handleShowModalFromOrderList(index);
    },
    handleUpdateOrderList(index, isHideBySuccess) {
      this.orders[index].isPaid = isHideBySuccess ? 1 : 0;
    },

    /* Update cod */
    async handleCodUpdate(key, item, index) {
      this.codUpdate = { indexUpdate: index, key };
      const data = {
        [key]: item[key] || null,
      };
      const { orderId } = item;
      const res = await this.updateOrder({
        orderId,
        ...data,
      });
      if (!res) return;
      this.showToastSuccess(
        null,
        `Cập nhật ${
          key === "codTaoBao" ? "mã taobao" : "mã tracking TQ"
        } thành công`
      );
      if (
        item?.status === OrderStatusEnum.Accepted &&
        this.handleCheckCodRefreshTable(data, item)
      ) {
        this.handleRefreshTable();
      }
    },
    handleCheckCodRefreshTable(data, item) {
      const { codTracking, codTaoBao } = data;
      const { codTaoBao: codTaoBaoOrder, codTracking: codTrackingOrder } = item;
      if (codTaoBaoOrder && codTrackingOrder) return true;
      if (codTracking && codTaoBaoOrder) return true;
      if (codTaoBao && codTrackingOrder) return true;
      return false;
    },
    handleRefreshTable() {
      this.refUserListTable.refresh();
      this.handleHideModal();
    },
    handleHideModal() {
      this.orderStatus = null;
    },
    /* status */
    async handleUpdateStatus(item) {
      this.orderStatus = item;
      setTimeout(() => this.$refs?.modalRef?.handleShowModal(false), 0);
    },
  },
  async created() {
    const users = await this.getUserFilter();
    this.userFilterOption = [
      ...this.userFilterOption,
      ...users.map((user) => {
        return {
          label: `${user.fullName} (${user.userId})`,
          value: user.userId,
        };
      }),
    ];
  },
  mounted() {
    this.refUsers = this.refUserListTable;
  },

  setup() {
    return {
      ...useTable(),
      ...useOrders(),
      ...useToastHandler(),
      localize,
      OrderStatusParse,
      formatCurrency,
      customTime,
      PaidEnumParse,
      OrderStatusEnum,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.status-filter-selector {
  width: 180px;
}

.filter-selector {
  width: 200px;
  z-index: 1000;
}
.filter-selector .vs__selected-options .vs__selected {
  text-wrap: nowrap;
  overflow-x: auto;
  width: 145px;
}

.filter-selector .vs__dropdown-menu {
  min-width: 200px;
  width: auto !important;
  // background-color: red !important;
}
.filter-selector .vs__dropdown-option--selected::after {
  display: none !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
