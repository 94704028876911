<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="12" md="6" class="">
        <span>
          <strong v-if="userDetail.roleId == RoleEnum.User"
            >Khách hàng:
          </strong>
          <strong v-else>Cộng tác viên: </strong>
          {{ userDetail.fullName }} - {{ userDetail.username }}</span
        >
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-end justify-content-end"
      >
        <b-button
          @click="handleSubmitDepositBalance"
          size="md"
          variant="primary mr-1"
        >
          <span class="ml-25 align-middle">Nạp tiền</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <Filters :type-filter.sync="typeFilter" />
    <b-card no-body class="mb-0">
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ localize["table_show"] }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Table Control -->
      <b-table
        ref="refUserListTable"
        :items="fetchDataTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        primary-key="id"
        hover
        fieldsKeyCase="original"
      >
        <template #empty>
          <div v-if="state.loading" class="text-center my-2">
            <p>{{ localize["table_loading"] }}</p>
          </div>
          <div
            v-if="!state.loading && totalRows === 0"
            class="text-center my-2"
          >
            {{ localize["table_empty"] }}
          </div>
        </template>

        <!-- Custom Column -->

        <!-- <template #cell(content)="{ item }">
          <div class="text-wrap" style="width: 120px">
            {{ item.content }}
          </div>
        </template> -->

        <template #cell(content)="{ item }">
          <div class="text-wrap" style="width: 120px">
            {{ item.content }}
          </div>
        </template>

        <template #cell(fromBalance)="{ item }">
          <div class="text-wrap" style="width: 120px">
            {{ formatCurrency(item.fromBalance) }} đ
          </div>
        </template>

        <template #cell(balance)="{ item }">
          <div v-if="item.balance > 0" style="width: 120px">
            <span class="text-wrap text-success">
              +{{ formatCurrency(item.balance) }} đ</span
            >
          </div>
          <div v-else class="text-wrap text-danger" style="width: 120px">
            {{ formatCurrency(item.balance) }} đ
          </div>
        </template>

        <template #cell(toBalance)="{ item }">
          <div class="text-wrap" style="width: 120px">
            {{ formatCurrency(item.toBalance) }} đ
          </div>
        </template>

        <template #cell(refOrderId)="{ item }">
          <div style="display: flex; justify-content: center">
            <a
              style="width: 150px"
              v-if="item.refOrderId"
              :href="`${EnvConfig.baseUrl}/order/${item.refOrderId}`"
            >
              {{ item.refOrderId }}
            </a>
            <span v-else>-</span>
          </div>
        </template>

        <template #cell(type)="{ item }">
          <div class="text-center">
            <b-badge
              pill
              :variant="`${resolveType(item.type)}`"
              class="text-capitalize"
            >
              {{ TransactionsContent[item.type] }}
            </b-badge>
          </div>
        </template>

        <template #cell(createdDate)="{ item }">
          <div
            class="text-center"
            style="display: flex; justify-content: center"
          >
            <div style="width: 150px">
              {{ customTime(item.createdDate) }}
            </div>
          </div>
        </template>
      </b-table>

      <!-- Pagination < Pre/Next > -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <DepositBalance
      v-if="userDetail"
      :handleUpdateInformation="handleUpdateInformation"
      :user-detail="userDetail"
      ref="depositBalanceRef"
    />
  </div>
</template>

<script>
import { EnvConfig } from "@/@core/config/index";
import { OrderStatusParse } from "@/@core/utils/enums/order.enum";
import { SortByEnum } from "@/@core/utils/enums/sort-by.enum";
import { TransactionsContent } from "@/@core/utils/enums/transaction.enum";
import { RoleEnum } from "@/@core/utils/enums/user.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { functionProp, objectProp } from "@/helpers/props";
import { customTime, formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import DepositBalance from "../Info/InfoPartial/modal/DepositBalance.vue";
import Filters from "./Filters.vue";
import userTransaction from "./useTransaction";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    BButton,
    Filters,
    BCardHeader,
    BCardBody,
    DepositBalance,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    userDetail: objectProp(),
    handleRefreshUserDetail: functionProp(),
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      state: (s) => {
        return s.apps.transaction.transactions;
      },
    }),
  },
  mounted() {},

  methods: {
    ...mapActions({
      getTransactions: "transaction/transactions/getTransactions",
    }),
    async fetchDataTable() {
      const { userId } = this.userDetail;
      const data = await this.getTransactions({
        userId,
        limit: this.perPage,
        page: this.currentPage,
        order: this.sortBy ?? null,
        type: this.typeFilter,
        by: this.sortBy
          ? this.isSortDirDesc
            ? SortByEnum.DESC
            : SortByEnum.ASC
          : null,
      });
      this.totalRows = this.state.totalPage;
      return data;
    },
    handleSubmitDepositBalance() {
      this.$refs.depositBalanceRef.handleShowModal();
    },
    handleUpdateInformation() {
      this.refUserListTable.refresh();
      this.handleRefreshUserDetail();
    },
  },
  mounted() {
    this.refUsers = this.refUserListTable;
  },
  setup() {
    return {
      ...useTable(),
      ...userTransaction(),
      ...useToastHandler(),
      localize,
      OrderStatusParse,
      EnvConfig,
      customTime,
      TransactionsContent,
      RoleEnum,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
