<template>
  <validation-observer ref="depositBalanceRef" #default="{ valid }">
    <b-modal
      ref="modal"
      title="Nạp tiền"
      centered
      cancel-variant="outline-secondary"
      @ok.prevent="handleDepositBalance"
      :ok-disabled="loading || !valid"
      @cancel="resetForm"
    >
      <b-row>
        <!-- Field: Password -->
        <b-col cols="8">
          <b-form-group label="Số tiền" label-for="balance">
            <validation-provider
              #default="{ errors }"
              name="balance"
              vid="balance"
              rules="required"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-currency="config"
                  ref="inputFormatRef"
                  v-model="balance"
                  id="balance"
                  placeholder=""
                />

                <b-input-group-append is-text>đ</b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{
                errors[0] && "Số tiền không hợp lệ."
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex">
            <label for="content">Nội dung nạp tiền</label>
          </div>
          <b-form-textarea
            id="content"
            v-model="content"
            rows="4"
            maxlength="1000"
            placeholder=""
          />
        </b-col>
      </b-row>
      <template #modal-cancel>
        <span>Từ chối</span>
      </template>
      <template #modal-ok>
        <Spinner v-if="loading" />
        <span v-else>Chấp nhận</span>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { functionProp, objectProp } from "@/helpers/props";
import { formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    VBModal,
    ValidationObserver,
    ValidationProvider,
    Spinner,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    handleUpdateInformation: functionProp(),
    userDetail: objectProp(),
  },
  data() {
    return {
      balance: "",
      content: null,
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.apps.users.depositBalance.loading,
    }),
    config() {
      return {
        currency: null,
        precision: { min: 0, max: 2 },
      };
    },
  },
  methods: {
    ...mapActions({
      depositBalance: "users/depositBalance/depositBalance",
    }),
    async handleDepositBalance() {
      const { userId } = this.$router.currentRoute.params;
      const { content } = this;
      const balance = this.$parseCurrency(this.balance, {
        locale: "en",
        currency: null,
      });
      const res = await this.depositBalance({
        userId,
        content,
        balance,
      });
      if (!res) return;

      /* Update user detail*/
      const toBalance = this.userDetail?.balance ?? 0;
      this.handleUpdateInformation({
        balance: Number(toBalance) + Number(balance),
      });
      this.showToastSuccess(null, "Nạp tiền thành công.");
      this.resetForm();
    },
    handleShowModal() {
      this.$refs.modal.show();
    },
    resetForm() {
      this.balance = null;
      this.content = null;
      this.$refs.depositBalanceRef.reset();
      this.$refs.modal.hide();
    },
  },
  setup() {
    return {
      ...useToastHandler(),
      formatCurrency,
    };
  },
};
</script>
