<template>
  <div class="mt-2">
    <span> <strong>Tài khoản thanh toán: </strong></span>
    <span v-if="userDetail.payment">
      {{ userDetail.payment.cardNumber }} - {{ userDetail.payment.bankName }}
    </span>
    <span v-else> - </span>
    <!-- :disabled="loading" -->
    <!-- Đổi mật khẩu -->
    <b-col class="mt-2">
      <b-row cols="12">
        <!-- Đổi mật khẩu -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="changePassword"
          @click.prevent="showModal('change-password')"
        >
          Đổi mật khẩu
        </b-button>

        <!-- Điều chỉnh tỷ giá -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="discount"
          @click.prevent="showModal('discount')"
        >
          Điều chỉnh chiết khấu
        </b-button>

        <!-- Nạp tiền -->

        <b-button
          class="mt-1 mr-1"
          variant="outline-secondary"
          type="paymentBalance"
          @click.prevent="showModal('payment-balance')"
        >
          Thanh toán số dư
        </b-button>
      </b-row>
    </b-col>
    <ChangePassword ref="changePasswordRef" />
    <ChangeDiscount
      :handleUpdateInformation="handleUpdateInformation"
      ref="changeDiscount"
    />
    <PaymentBalance
      ref="paymentBalanceRef"
      :handleUpdateInformation="handleUpdateInformation"
    />
  </div>
</template>

<script>
import { functionProp, objectProp } from "@/helpers/props";
import { BButton, BCol, BRow } from "bootstrap-vue";
import ChangeDiscount from "./modal/ChangeDiscount.vue";
import ChangePassword from "./modal/ChangePassword.vue";
import PaymentBalance from "./modal/PaymentBalance.vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    ChangePassword,
    ChangeDiscount,
    PaymentBalance,
  },
  props: {
    userDetail: objectProp(),
    handleUpdateInformation: functionProp(),
  },
  methods: {
    showModal(type) {
      switch (type) {
        case "change-password":
          return this.$refs.changePasswordRef.handleShowModal();
        case "discount":
          return this.$refs.changeDiscount.handleShowModal();
        case "payment-balance":
          return this.$refs.paymentBalanceRef.handleShowModal();
      }
    },
  },
};
</script>
