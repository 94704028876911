<template>
  <validation-observer ref="changeExchangeRateRef" #default="{ valid }">
    <b-modal
      ref="modal"
      title="Điều chỉnh tỷ giá"
      centered
      cancel-variant="outline-secondary"
      @ok.prevent="handleDepositBalance"
      :ok-disabled="loading || !valid"
      @cancel="resetForm"
    >
      <b-row>
        <!-- Field: Password -->
        <b-col cols="8">
          <b-form-group label="Tỷ giá" label-for="exchangeRate">
            <validation-provider
              #default="{ errors }"
              name="exchangeRate"
              vid="exchangeRate"
              rules="required"
            >
              <b-input-group class="input-group-merge" s>
                <b-form-input
                  v-currency="config"
                  v-model="exchangeRate"
                  name="exchangeRate"
                  placeholder=""
                />
                <b-input-group-append is-text>đ</b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{
                errors[0] && "Tỷ giá không hợp lệ."
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-cancel>
        <span>Từ chối</span>
      </template>
      <template #modal-ok>
        <Spinner v-if="loading" />
        <span v-else>Chấp nhận</span>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ExchangeRateEnum } from "@/@core/utils/enums/exchange-rate.enum";
import { functionProp, objectProp } from "@/helpers/props";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    VBModal,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,

    Spinner,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    handleUpdateInformation: functionProp(),
    userDetail: objectProp(),
  },
  data() {
    return {
      exchangeRate: null,
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.apps.users.changeExchangeRate.loading,
    }),
    config() {
      return {
        currency: null,
        precision: { min: 0, max: 2 },
      };
    },
  },
  methods: {
    ...mapActions({
      changeExchangeRate: "users/changeExchangeRate/changeExchangeRate",
    }),
    async handleDepositBalance() {
      const { userId } = this.$router.currentRoute.params;
      const exchangeRate = this.$parseCurrency(this.exchangeRate, {
        locale: "en",
        currency: null,
      });
      const exchangeRateType = ExchangeRateEnum.CNY_TO_VND;
      const res = await this.changeExchangeRate({
        userId: Number(userId),
        exchangeRateType,
        exchangeRate: Number(exchangeRate),
      });
      if (!res) return;

      /* Update user detail*/
      this.handleUpdateInformation({
        exchangeRate: Number(exchangeRate),
      });
      this.showToastSuccess(null, "Điều chỉnh tỷ giá thành công.");
      this.resetForm();
    },
    handleShowModal() {
      this.$refs.modal.show();
    },
    resetForm() {
      this.exchangeRate = null;
      this.$refs.changeExchangeRateRef.reset();
      this.$refs.modal.hide();
    },
  },
  setup() {
    return {
      ...useToastHandler(),
    };
  },
};
</script>
