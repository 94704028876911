<template>
  <b-card no-body class="mb-0">
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0">
          <label>Loại</label>
          <v-select
            :aria-autocomplete="false"
            :value="typeFilter"
            :options="typeOptions"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:typeFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { isRoleSuperAdmin } from "@/helpers/permission";
import { numberProp } from "@/helpers/props";
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BLink,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useTransaction from "./useTransaction";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BLink,
  },
  props: {
    typeFilter: numberProp(),
  },

  setup() {
    const { typeOptions } = useTransaction();

    return {
      typeOptions,
      isRoleSuperAdmin,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
