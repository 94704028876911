<template>
  <b-modal
    ref="modal"
    :title="
      allowed_search_product ? 'Huỷ xác mình tài khoản' : 'Xác minh tài khoản'
    "
    centered
    cancel-variant="outline-secondary"
    @ok="handleVerifyAccount"
    :ok-disabled="loading"
  >
    <span>
      Bạn chắc chắn muốn
      {{
        allowed_search_product
          ? "huỷ xác mình tài khoản"
          : "xác minh tài khoản"
      }}?</span
    >
    <template #modal-cancel>
      <span>Từ chối</span>
    </template>
    <template #modal-ok>
      <Spinner v-if="loading" />
      <span v-else>Chấp nhận</span>
    </template>
  </b-modal>
</template>

<script>
import { booleanProp, functionProp } from "@/helpers/props";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import { BButton, BCol, BFormTextarea, BRow, VBModal } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    VBModal,
    Spinner,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    handleVerifyAccount: functionProp(),
    allowed_search_product: booleanProp(),
  },
  data() {
    return {
      title: this.allowed_search_product
        ? "Huỷ xác mình tài khoản"
        : "Xác minh tài khoản",
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.apps.users.paymentBalance.loading,
    }),
  },
  methods: {
    ...mapActions({
      paymentBalance: "users/paymentBalance/paymentBalance",
    }),
    handleShowModal() {
      this.$refs.modal.show();
    },
  },
  setup() {
    return {
      ...useToastHandler(),
    };
  },
};
</script>
