import {
  OrderStatusEnum,
  OrderStatusParse,
  PaidEnum,
} from "@/@core/utils/enums/order.enum";
import { customTime } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";
import { debounce } from "debounce";
// Notification

export default function useUsers() {
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const search = ref(null);
  const codTaoBao = ref(null);
  const codTracking = ref(null);
  const userFilter = ref({ label: "Tất cả", value: 0 });
  const statusFilter = ref({ label: "Tất cả", value: -1 });

  watch([statusFilter, userFilter], () => {
    refUsers.value.refresh();
  });

  watch(
    [search, codTaoBao, codTracking],
    debounce(() => {
      refUsers.value.refresh();
    }, 700)
  );

  const tableColumns = [
    {
      key: "orderId",
      label: "Mã ĐH",
    },
    {
      key: "codTaoBao",
      label: "Mã taobao",
      formatter: (value) => {
        return value ?? "-";
      },
    },
    {
      key: "codTracking",
      label: "Mã Tracking TQ",
      formatter: (value) => {
        return value ?? "-";
      },
    },
    {
      key: "fullName",
      label: "Khách hàng",
      thClass: "text-center",
    },
    {
      key: "totalQuotePrices",
      label: "Tổng Tiền",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "isPaid",
      label: "Thanh toán",
      thClass: "text-center",
      tdClass: "text-center",
      sortable: true,
    },
    {
      key: "status",
      label: "Trạng Thái",
      thClass: "text-center",
    },
    {
      key: "createdDate",
      label: "Ngày tạo",
      thClass: "text-center",
      thStyle: "width: 150px",
      tdClass: "text-center",
      sortable: true,
      formatter: (value) => {
        return customTime(value);
      },
    },
  ];

  const statusOptions = [
    {
      label: "Tất cả",
      value: -1,
    },

    {
      label: OrderStatusParse[OrderStatusEnum.Waiting_Quote],
      value: OrderStatusEnum.Waiting_Quote,
    },
    {
      label: OrderStatusParse[OrderStatusEnum.Quoted],
      value: OrderStatusEnum.Quoted,
    },
    {
      label: OrderStatusParse[OrderStatusEnum.Accepted],
      value: OrderStatusEnum.Accepted,
    },
    {
      label: OrderStatusParse[OrderStatusEnum.Rejected],
      value: OrderStatusEnum.Rejected,
    },
    {
      label: OrderStatusParse[OrderStatusEnum.Processing],
      value: OrderStatusEnum.Processing,
    },
    {
      label: OrderStatusParse[OrderStatusEnum.Landing],
      value: OrderStatusEnum.Landing,
    },
    {
      label: OrderStatusParse[OrderStatusEnum.Completed],
      value: OrderStatusEnum.Completed,
    },
  ];

  const resolveOrderStatus = (status) => {
    switch (status) {
      case OrderStatusEnum.Waiting_Quote:
        return "secondary";
      case OrderStatusEnum.Quoted:
        return "dark";
      case OrderStatusEnum.Accepted:
        return "primary";
      case OrderStatusEnum.Rejected:
        return "danger";
      case OrderStatusEnum.Processing:
        return "info";
      case OrderStatusEnum.Delivery:
        return "warning";
      case OrderStatusEnum.Completed:
        return "success";
    }
  };

  const resolveStyleOrderStatus = (status) => {
    switch (status) {
      case OrderStatusEnum.Landing:
        return "background-color: #FF00FF; color: #FFFFFF";
    }
  };

  const resolvePaid = (isPaid) => {
    switch (isPaid) {
      case PaidEnum.Non:
        return "danger";
      case PaidEnum.Paid:
        return "success";
    }
  };

  return {
    tableColumns,
    statusOptions,
    statusFilter,
    sortBy,
    isSortDirDesc,

    refUsers,
    resolveOrderStatus,
    resolveStyleOrderStatus,

    resolvePaid,

    search,
    codTaoBao,
    codTracking,
    userFilter,
  };
}
