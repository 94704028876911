var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"8"}},[_c('label',[_vm._v(_vm._s(_vm.localize["table_show"]))]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),_c('b-table',{ref:"refUserListTable",staticStyle:{"min-height":"500px"},attrs:{"items":_vm.fetchDataTable,"fields":_vm.tableColumns,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"responsive":"","show-empty":"","primary-key":"id","hover":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.state.loading)?_c('div',{staticClass:"text-center my-2"},[_c('p',[_vm._v(_vm._s(_vm.localize["table_loading"]))])]):_vm._e()]},proxy:true},{key:"cell(orderId)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{on:{"click":function($event){return _vm.handleRowClicked(item, index)}}},[(index === 0)?_c('div',[_c('b-form-input',{staticStyle:{"height":"38px","width":"155px"},attrs:{"placeholder":"Mã ĐH","id":"quality"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_c('div',{staticStyle:{"width":"155px"}},[_vm._v(" "+_vm._s(item.orderId)+" ")])])]}},{key:"cell(codTaoBao)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('div',[_c('b-form-input',{staticStyle:{"height":"38px","width":"150px"},attrs:{"placeholder":"Mã taobao","id":"quality"},model:{value:(_vm.codTaoBao),callback:function ($$v) {_vm.codTaoBao=$$v},expression:"codTaoBao"}})],1):_c('div',{staticClass:"text-wrap",staticStyle:{"width":"100px"}},[_c('b-form-input',{staticStyle:{"height":"30px","width":"150px"},attrs:{"id":"quality","disabled":_vm.codUpdate.indexUpdate == index &&
              _vm.codUpdate.key == 'codTaoBao' &&
              _vm.updateOrderLoading},on:{"change":function($event){return _vm.handleCodUpdate('codTaoBao', item, index)}},model:{value:(_vm.orders[index].codTaoBao),callback:function ($$v) {_vm.$set(_vm.orders[index], "codTaoBao", $$v)},expression:"orders[index].codTaoBao"}})],1)]}},{key:"cell(codTracking)",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0)?_c('div',[_c('b-form-input',{staticStyle:{"height":"38px","width":"150px"},attrs:{"placeholder":"Mã tracking TQ","id":"quality"},model:{value:(_vm.codTracking),callback:function ($$v) {_vm.codTracking=$$v},expression:"codTracking"}})],1):_c('div',{staticClass:"text-wrap",staticStyle:{"width":"120px"}},[_c('b-form-input',{staticStyle:{"height":"30px","width":"150px"},attrs:{"id":"quality","disabled":_vm.codUpdate.indexUpdate == index &&
              _vm.codUpdate.key == 'codTracking' &&
              _vm.updateOrderLoading},on:{"change":function($event){return _vm.handleCodUpdate('codTracking', item, index)}},model:{value:(_vm.orders[index].codTracking),callback:function ($$v) {_vm.$set(_vm.orders[index], "codTracking", $$v)},expression:"orders[index].codTracking"}})],1)]}},{key:"cell(fullName)",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('div',{on:{"click":function($event){return _vm.handleRowClicked(item, index)}}},[(index === 0 && !_vm.userId)?_c('div',[_c('v-select',{staticClass:"filter-selector d-inline-block",attrs:{"aria-autocomplete":false,"clearable":false,"options":_vm.userFilterOption},model:{value:(_vm.userFilter),callback:function ($$v) {_vm.userFilter=$$v},expression:"userFilter"}})],1):_c('div',{staticClass:"text-wrap text-center",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(item.fullName)+" ")])])]}},{key:"cell(totalQuotePrices)",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('div',{staticStyle:{"width":"110px"},on:{"click":function($event){return _vm.handleRowClicked(item, index)}}},[(index > 0)?_c('div',[_vm._v(" "+_vm._s(item.status === 0 || (item.status === 3 && !item.totalQuotePrice) ? _vm.formatCurrency(item.totalExchangePrice) : _vm.formatCurrency( (item.totalQuotePrice || 0) + (item.exchangeServiceCosts || 0) + (item.exchangeShipCosts || 0) + (item.totalOtherPrice || 0) ))+" đ ")]):_vm._e()])]}},{key:"cell(isPaid)",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('div',{staticStyle:{"min-width":"100px","display":"flex","align-items":"center","justify-content":"center"}},[(
              index > 0 &&
              item.status >= _vm.OrderStatusEnum.Quoted &&
              item.status != _vm.OrderStatusEnum.Rejected
            )?_c('b-form-checkbox',{key:index,attrs:{"value":1,"unchecked-value":0,"disabled":!!_vm.orders[index].isPaid},on:{"change":function (val) { return _vm.handlePayment(val, item, index); }},model:{value:(_vm.orders[index].isPaid),callback:function ($$v) {_vm.$set(_vm.orders[index], "isPaid", $$v)},expression:"orders[index].isPaid"}}):_vm._e()],1)]}},{key:"cell(status)",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(index === 0)?_c('div',[_c('v-select',{staticClass:"status-filter-selector d-inline-block",attrs:{"aria-autocomplete":false,"clearable":false,"options":_vm.statusOptions},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1):_c('div',{staticStyle:{"display":"flex","width":"180px","align-items":"center","flex":"1"}},[_c('div',{staticStyle:{"width":"160px","display":"flex","justify-content":"center"}},[_c('b-badge',{staticClass:"text-capitalize",staticStyle:{"padding-top":"5px","padding-bottom":"5px","padding-left":"14px","padding-right":"14px","margin-left":"40px"},style:(_vm.resolveStyleOrderStatus(item.status)),attrs:{"pill":"","variant":("" + (_vm.resolveOrderStatus(item.status)))}},[_vm._v(" "+_vm._s(_vm.OrderStatusParse[item.status])+" ")])],1),(item.status !== _vm.OrderStatusEnum.Completed)?_c('div',{staticStyle:{"display":"flex","justify-content":"right","color":"#4d73ad"}},[_c('feather-icon',{key:index,staticClass:"ml-1 cursor-pointer",style:(_vm.resolveStyleOrderStatus(item.value)),attrs:{"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.handleUpdateStatus(item)}}})],1):_vm._e()])])]}},{key:"cell(createdDate)",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('div',{staticStyle:{"width":"150px","display":"flex","justify-content":"center"}},[(index > 0)?_c('div',{staticClass:"text-wrap text-center"},[_vm._v(" "+_vm._s(_vm.customTime(item.createdDate))+" ")]):_vm._e()])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Showing "+_vm._s(_vm.totalRows === 0 ? 0 : _vm.dataMeta.from)+" to "+_vm._s(_vm.totalRows === 0 ? 0 : _vm.dataMeta.to - 1)+" of "+_vm._s(_vm.dataMeta.of)+" entries ")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('UpdateOrderModal',{ref:"updateOrderModalRef",attrs:{"order":{ orderId: _vm.order.orderId },"handleUpdateOrderList":_vm.handleUpdateOrderList}}),(_vm.orderStatus && _vm.order.status !== _vm.OrderStatusEnum.Completed)?_c('Modal',{ref:"modalRef",attrs:{"order":_vm.orderStatus,"refreshOrder":_vm.handleRefreshTable,"handleHideModal":_vm.handleHideModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }